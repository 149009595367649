export const stockData = {
  // table 属性
  tableList: [
    { name: "文件编号", text: "fileId" },
    { name: "文件名称", text: "name" },
    { name: "绑定标的", text: "tiedStockList" },
    { name: "文件类型", text: "fileTypeName" },
    { name: "状态", text: "fileStateName" },
  ],
  currentPage: 1,
  pageSize: 5,
  total: 0,
  loading: true,
  spreadTable: [],
  operation: ["查看", "通过", "否决"],
  heightCharts: 300,

  tableListPlan: [
    { name: "代码", text: "name" },
    { name: "简称", text: "internalsid" },
    { name: "数目", text: "targetamount" },
  ],
  currentPagePlan: 1,
  pageSizePlan: 20,
  totalPlan: 0,
  loadingPlan: true,
  spreadTablePlan: [],
  tableListUsa: [
    { name: "代码", text: "marketSID" },
    { name: "名称", text: "name" },
  ],
  currentPageUsa: 1,
  pageSizeUsa: 5,
  totalUsa: 0,
  loadingUsa: true,
  spreadTableUsa: [],

  tableListAll: [
    { name: "代码", text: "marketSID" },
    { name: "名称", text: "name" },
    { name: "数目", text: "num" },
  ],
  currentPageAll: 0,
  pageSizeAll: 5,
  totalAll: 0,
  loadingAll: true,
  spreadTableAll: [],

  selectList: [],

  numInput: 0,
  nowHightTable: 813,
  pageType: 2,
  filetype: 1,
  routeId: undefined,

  // 查看
  fileName: "",
  diaStart: false,
  valueFile: "",
};


